<script setup>
import moment from "moment";
const props = defineProps({
  selectedPeriod: {
    type: Object,
    required: true,
  },
  setSelectedPeriod: {
    type: Function,
    required: true,
  },
});
const emit = defineEmits(["close"]);

const selectedTimeRange = ref();

const today = new Date();
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 3);
const maxDate = ref(today);
const minDate = ref(threeMonthsAgo);

const onChange = (e) => {
  const [start, end] = selectedTimeRange.value;

  if (start && end) {
    if (start.toDateString() == end.toDateString()) {
      start.set({
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      end.set({
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      });
    }

    props.setSelectedPeriod({
      start: moment(start),
      end: moment(end),
    });

    emit("close");
  }
};

onMounted(() => {
  if (props.selectedPeriod) {
    const { start, end } = props.selectedPeriod;
    selectedTimeRange.value = [start._d, end._d];
  }
});
</script>
<template>
  <Dialog
    :visible="true"
    :modal="true"
    :dismissableMask="true"
    :showHeader="false"
    contentClass="!p-0"
    @update:visible="$emit('close')"
  >
    <Calendar
      v-model="selectedTimeRange"
      :inline="true"
      :showTime="true"
      :hideOnRangeSelection="true"
      :minDate="minDate"
      :maxDate="maxDate"
      selectionMode="range"
      :numberOfMonths="3"
      :touchUI="true"
      :showButtonBar="true"
      @date-select="onChange(e)"
      id="custom-analytics-datetime-range"
    />
  </Dialog>
</template>

<style></style>
